<template>
    <div>
        <select v-model="$i18n.locale" class="hover:bg-gray-300 transition-all rounded-xl">
            <option
                class=""
                v-for="(lang, i) in langs"
                :key="`lang-${i}`"
                :value="lang"
            >
                {{ lang }}
            </option>
        </select>
    </div>
</template>

<script>
    export default {
        name: 'SelectLocale',
        data() {
            return { langs: ['English', 'Deutsch'] }
        }
    }
</script>