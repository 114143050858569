<template>
  <div id="app">
    <div class="flex justify-center">
      <div class="container mx-auto p-7">
        <div class="float-right">
          <div class="languages">
            <SelectLocale />
          </div>
        </div>

        <div ref="logoform">
          <div class="logo" ref="logodiv">
            <a href="/">
              <img src="logo_final_fullstaple.svg" alt="Pagespeed.hosting" />
            </a>
          </div>
          <form @submit.prevent="check" id="analyzeForm" ref="form">
            <div
              class="flex before border-gradient-purple items-center bg-white overflow-hidden p-3 justify-between w-4/5 mx-auto rounded-xl"
              ref="gradient"
            >
              <select
                v-model="protocol"
                ref="protocol"
                class="hover:bg-gray-300 transition-all protocol bg-fafafa text-base text-gray-900 outline-none rounded-xl px-3 py-2"
              >
                <option value=""></option>
                <option value="http">HTTP</option>
                <option value="https">HTTPS</option>
              </select>
              <input
                v-model="url"
                ref="input"
                class="hover:bg-gray-300 transition-all before input-url bg-fafafa text-base text-gray-700 flex-grow outline-none px-2 rounded-xl"
                v-bind:placeholder="$t('message.enter_url')"
              />
              <div class="w-1/3 ms:flex items-center space-x-4">
                <input
                  type="submit"
                  v-bind:value="$t('message.analyze')"
                  ref="submit"
                  class="hover:shadow-xl transition-all hover:to-gradient before submit-btn text-white text-base rounded-xl p-2"
                />
              </div>
            </div>
          </form>
        </div>
        <div v-if="urlError" class="text-red-700 text-lg mt-2 text-center">
          {{ urlError }}
        </div>
        <hr v-if="analyze" class="m-8" />
      </div>
    </div>
    <!-- check for errors -->
    <vue-recaptcha
      v-if="detect >= 1"
      ref="recaptcha"
      @verify="onVerify"
      @expired="onExpired"
      sitekey="6LeR6JwdAAAAAG2N5Moh_G3OdtPBO7UPwLO20ZuL"
      class="my-4 mx-auto flex justify-center"
    ></vue-recaptcha>
    <div v-if="error" class="text-center text-4xl p-4 m-auto">{{ error }}</div>
    <div v-else-if="!loading && website_data">
      <div v-if="website_data.error">
        <div class="text-center text-4xl p-4 m-auto">
          {{ website_data.error }}
        </div>
      </div>
      <div v-else-if="!website_data.error" class="container m-auto">
        <div
          class="container mx-auto my-4 p-4 flex items-center justify-between"
        >
          <div class="p-4 my-4 mx-auto w-2/5 flex flex-col items-center">
            <b class="text-3xl font-bold px-2 mx-auto"
              >{{ $t("message.last_followed_url") }} :
            </b>
            <span>
              <a
                :href="website_data.lastRedirect"
                class="text-2xl no-underline mx-auto"
                >{{ website_data.lastRedirect }}</a
              ></span
            >
            <div
              v-if="website_data.redirects"
              class="text-2xl mx-auto flex flex-col items-center justify-center"
            >
              <span
                ><b>{{ $t("message.redirects") }}:</b>
                {{ website_data.ttlRedirects }}</span
              >
              <ul>
                <li v-for="item in website_data.redirects" :key="item">
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
          <div
            v-if="imageBytes"
            class="p-4 my-4 mx-auto w-2/5 flex items-center"
          >
            <img
              class="rounded-xl shadow-md"
              :src="'data:image/jpeg;base64,' + imageBytes"
            />
          </div>
        </div>

        <div class="container m-auto">
          <h1 class="text-center text-4xl font-bold p-4">
            {{ $t("message.performance_timings") }}
          </h1>
          <Tl :metrics="metrics" />
          <div class="flex justify-around">
            <div
              class="text-xl bg-gray-200 shadow-md flex-1 p-4 my-4 mx-2 justify-center flex flex-col rounded-xl"
            >
              <!-- good ttfb <= 350 ok ttfb >350< 700 bad ttfb > 700 -->
              <div
                v-if="website_data.ttfb <= 350"
                class="flex justify-center flex-col items-center p-1 my-2 mx-auto w-2/4 shadow-md bg-green-400 has-tooltip rounded-xl"
              >
                <span class="tooltip">Time to first Byte</span>
                <h1 class="p-2">TTFB :</h1>
                <p>{{ website_data.ttfb }} ms</p>
              </div>
              <div
                v-if="350 < website_data.ttfb && website_data.ttfb <= 700"
                class="flex justify-center flex-col items-center p-1 my-2 mx-auto w-2/4 shadow-md bg-yellow-400 has-tooltip rounded-xl"
              >
                <span class="tooltip">Time to first Byte</span>
                <h1 class="p-2">TTFB :</h1>
                <p>{{ website_data.ttfb }} ms</p>
              </div>
              <div
                v-if="website_data.ttfb > 700"
                class="flex justify-center flex-col items-center p-1 my-2 mx-auto w-2/4 shadow-md bg-red-600 has-tooltip rounded-xl"
              >
                <span class="tooltip">Time to first Byte</span>
                <h1 class="p-2">TTFB :</h1>
                <p>{{ website_data.ttfb }} ms</p>
              </div>

              <div class="flex justify-center items-center p-2">
                <div
                  class="flex justify-center flex-1 flex-col items-center m-1 p-1 shadow-md bg-gray-50 has-tooltip rounded-xl"
                >
                  <span class="tooltip">First Paint</span>
                  <h2 class="p-2">FP:</h2>
                  <p>{{ website_data.fp }} ms</p>
                </div>
                <!-- good FCP <= 1800 ok FCP >1800< 3000 bad FCP > 3000 -->
                <div
                  class="flex justify-center flex-1 flex-col items-center m-1 p-1 bg-green-400 shadow-md has-tooltip rounded-xl"
                  v-if="website_data.fcp <= 1800"
                >
                  <span class="tooltip">First Contentful Paint</span>
                  <h2 class="p-2">FCP :</h2>
                  <p>{{ website_data.fcp }} ms</p>
                </div>
                <div
                  class="flex justify-center flex-1 flex-col items-center m-1 p-1 bg-yellow-400 shadow-md has-tooltip rounded-xl"
                  v-if="website_data.fcp > 1800 && website_data.fcp <= 3000"
                >
                  <span class="tooltip">First Contentful Paint</span>
                  <h2 class="p-2">FCP :</h2>
                  <p>{{ website_data.fcp }} ms</p>
                </div>
                <div
                  class="flex justify-center flex-1 flex-col items-center m-1 p-1 shadow-md bg-red-600 has-tooltip rounded-xl"
                  v-if="website_data.fcp > 3000"
                >
                  <span class="tooltip">First Contentful Paint</span>
                  <h2 class="p-2">FCP :</h2>
                  <p>{{ website_data.fcp }} ms</p>
                </div>
              </div>
            </div>

            <div
              class="text-xl bg-gray-200 shadow-md flex-1 p-4 my-4 mx-2 justify-center flex flex-col rounded-xl"
            >
              <div class="flex justify-center items-center p-2">
                <!-- good pageload <= 2 ok pageload >2< 3 bad pageload > 3 -->
                <div
                  class="flex justify-center flex-1 flex-col items-center m-1 p-1 shadow-md bg-green-400 has-tooltip rounded-xl"
                  v-if="website_data.pageMetrics.TaskDuration <= 2"
                >
                  <span class="tooltip">{{
                    $t("message.total_loading_time")
                  }}</span>
                  <p>
                    {{ website_data.pageMetrics.TaskDuration }}
                    {{ $t("message.sec") }}
                  </p>
                  <h2 class="p-2">{{ $t("message.loading_time") }}</h2>
                </div>
                <div
                  class="flex justify-center flex-1 flex-col items-center m-1 p-1 shadow-md bg-yellow-400 has-tooltip rounded-xl"
                  v-if="
                    website_data.pageMetrics.TaskDuration > 2 &&
                    website_data.pageMetrics.TaskDuration <= 3
                  "
                >
                  <span class="tooltip">{{
                    $t("message.total_loading_time")
                  }}</span>
                  <p>
                    {{ website_data.pageMetrics.TaskDuration }}
                    {{ $t("message.sec") }}
                  </p>
                  <h2 class="p-2">{{ $t("message.loading_time") }}</h2>
                </div>
                <div
                  class="flex justify-center flex-1 flex-col items-center m-1 p-1 shadow-md bg-red-600 has-tooltip rounded-xl"
                  v-if="website_data.pageMetrics.TaskDuration > 3"
                >
                  <span class="tooltip">{{
                    $t("message.total_loading_time")
                  }}</span>
                  <p>
                    {{ website_data.pageMetrics.TaskDuration }}
                    {{ $t("message.sec") }}
                  </p>
                  <h2 class="p-2">{{ $t("message.loading_time") }}</h2>
                </div>
                <div
                  class="flex justify-center flex-1 flex-col items-center m-1 p-1 shadow-md bg-gray-50 has-tooltip rounded-xl"
                >
                  <span class="tooltip">{{
                    $t("message.total_time_taken_for_javasctript")
                  }}</span>
                  <p>
                    {{ website_data.pageMetrics.ScriptDuration }}
                    {{ $t("message.sec") }}
                  </p>
                  <h2 class="p-2">JS time</h2>
                </div>
              </div>
              <div
                class="flex justify-center flex-col items-center p-1 my-1 mx-auto w-2/3 shadow-md bg-gray-50 has-tooltip rounded-xl"
              >
                <span class="tooltip">{{
                  $t("message.total_time_taken_for_CSS")
                }}</span>
                <p>
                  {{ website_data.pageMetrics.RecalcStyleDuration }}
                  {{ $t("message.sec") }}
                </p>
                <h1 class="p-2">CSS time</h1>
              </div>
            </div>

            <div
              class="text-xl bg-gray-200 shadow-md flex-1 p-4 my-4 mx-2 justify-center flex flex-col rounded-xl"
            >
              <div
                class="flex justify-center flex-col items-center p-1 my-1 mx-auto w-2/3 shadow-md bg-gray-50 has-tooltip rounded-xl"
              >
                <span class="tooltip">{{
                  $t("message.number_of_scripts_run_on_the_page")
                }}</span>
                <p>{{ website_data.chart.js.reqs }}</p>
                <h1 class="p-2">Scripts</h1>
              </div>
              <div class="flex justify-center items-center p-2">
                <div
                  class="flex justify-center flex-1 flex-col items-center m-1 p-1 shadow-md bg-gray-50 has-tooltip rounded-xl"
                >
                  <span class="tooltip">{{
                    $t("message.number_of_stylesheets_linked_on_the_page")
                  }}</span>
                  <p>{{ website_data.chart.css.reqs }}</p>
                  <h2 class="p-2">Stylesheets</h2>
                </div>
                <div
                  class="flex justify-center flex-1 flex-col items-center m-1 p-1 shadow-md bg-gray-50 has-tooltip rounded-xl"
                >
                  <p>{{ website_data.chart.font.reqs }}</p>
                  <span class="tooltip">{{
                    $t("message.number_of_Fonts_linked_on_the_page")
                  }}</span>
                  <h2 class="p-2">Fonts</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="website_data.mediaLnks.length !== 0" class="mb-8">
          <div
            class="overflow-y-auto p-2 mx-auto items-center flex-col border-2 h-64 min-w-min my-2 rounded-xl"
          >
            <div
              class="p-2 flex flex-1 min-w-full items-center justify-between"
            >
              <div class="flex mx-auto w-4/5 border-r-2 font-bold items-center">
                <p class="mx-auto">Media Link</p>
              </div>
              <div class="flex w-1/5 flex-row">
                <div
                  class="px-2 text-gray-500 font-bold border-r-2 flex justify-center items-center w-1/2"
                >
                  {{ $t("message.size") }}
                </div>
                <div class="font-bold flex items-center justify-center w-1/2">
                  Media Preview
                </div>
              </div>
            </div>

            <div
              v-for="media in website_data.mediaLnks"
              :key="media"
              class="flex flex-1 min-w-full items-center justify-between p-1 my-1 bg-gray-200 shadow-sm hover:shadow-lg transition duration-500 rounded-xl"
            >
              <div class="flex p-1 px-3 w-4/5">
                <p class="break-all">{{ media.URL }}</p>
              </div>
              <div class="flex w-1/5 justify-around flex-row">
                <div
                  class="w-1/2 justify-center flex py-2 px-2 text-gray-500 font-semibold items-center"
                >
                  <p v-if="media.size >= 1000">
                    <span v-if="media.size >= 1000000"
                      >{{ (media.size / 1000000).toFixed(2) }} MB</span
                    >
                    <span v-else>{{ (media.size / 1000).toFixed(2) }}KB</span>
                  </p>
                  <p v-else>{{ media.size.toFixed(2) }} Byte</p>
                </div>

                <div
                  class="place-self-center flex justify-center w-1/2 p-1"
                  v-if="/image/.test(media.type)"
                >
                  <img
                    class="max-w-14 max-h-14 rounded-md"
                    loading="lazy"
                    :src="media.URL"
                  />
                </div>
                <div
                  class="place-self-center flex justify-center w-1/2 p-1"
                  v-if="/video/.test(media.type)"
                >
                  <BIconPlayCircleFill class="max-w-14 max-h-14 rounded-md" />
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex my-2 flex-1 min-w-full items-center justify-between p-2 pb-0"
          >
            <div class="flex mx-auto p-4 w-1/3 border-r-2 font-semibold">
              <p class="mx-auto">
                {{ $t("message.num_of_media") }} :
                {{ website_data.mediaLnks.length }}
              </p>
            </div>
            <div class="flex mx-auto p-4 w-1/3 border-r-2">
              <div class="mx-auto text-gray-500 font-semibold flex">
                {{ $t("message.size_of_media") }} :
                <span>
                  <p v-if="website_data.totalSize >= 1000">
                    <span v-if="website_data.totalSize >= 1000000"
                      >{{
                        (website_data.totalSize / 1000000).toFixed(2)
                      }}
                      MB</span
                    >
                    <span v-else
                      >{{ (website_data.totalSize / 1000).toFixed(2) }}KB</span
                    >
                  </p>
                  <p v-else>{{ website_data.totalSize.toFixed(2) }} Byte</p>
                </span>
              </div>
            </div>
            <div class="flex mx-auto p-4 w-1/3">
              <div class="mx-auto font-semibold flex">
                {{ $t("message.biggest_media") }} :
                <span v-if="/image/.test(this.website_data.mediaLnks[0].type)">
                  <div
                    v-if="website_data.mediaLnks[0].size >= 1500000"
                    class="text-red-600"
                  >
                    <span
                      >{{
                        (website_data.mediaLnks[0].size / 1000000).toFixed(2)
                      }}MB</span
                    >
                  </div>
                  <div
                    v-else-if="
                      website_data.mediaLnks[0].size < 1500000 &&
                      website_data.mediaLnks[0].size >= 700000
                    "
                    class="text-yellow-600"
                  >
                    <span v-if="website_data.mediaLnks[0].size >= 1000000"
                      >{{
                        (website_data.mediaLnks[0].size / 1000000).toFixed(2)
                      }}MB</span
                    >
                    <span v-else
                      >{{
                        (website_data.mediaLnks[0].size / 1000).toFixed(2)
                      }}KB</span
                    >
                  </div>
                  <div v-else class="text-green-400">
                    <span v-if="website_data.mediaLnks[0].size >= 10000"
                      >{{
                        (website_data.mediaLnks[0].size / 1000).toFixed(2)
                      }}KB</span
                    >
                    <span v-else
                      >{{ website_data.mediaLnks[0].size.toFixed(2) }}Byte</span
                    >
                  </div>
                </span>
                <span
                  v-else-if="/video/.test(this.website_data.mediaLnks[0].type)"
                >
                  <div
                    v-if="website_data.mediaLnks[0].size >= 5000000"
                    class="text-red-600"
                  >
                    <span
                      >{{
                        (website_data.mediaLnks[0].size / 1000000).toFixed(2)
                      }}MB</span
                    >
                  </div>
                  <div
                    v-else-if="
                      website_data.mediaLnks[0].size < 5000000 &&
                      website_data.mediaLnks[0].size >= 3000000
                    "
                    class="text-yellow-600"
                  >
                    <span v-if="website_data.mediaLnks[0].size >= 1000000"
                      >{{
                        (website_data.mediaLnks[0].size / 1000000).toFixed(2)
                      }}MB</span
                    >
                    <span v-else
                      >{{
                        (website_data.mediaLnks[0].size / 1000).toFixed(2)
                      }}KB</span
                    >
                  </div>
                  <div v-else class="text-green-400">
                    <span v-if="website_data.mediaLnks[0].size >= 1000000"
                      >{{
                        (website_data.mediaLnks[0].size / 1000000).toFixed(2)
                      }}MB</span
                    >
                    <span v-else-if="website_data.mediaLnks[0].size >= 10000"
                      >{{
                        (website_data.mediaLnks[0].size / 1000).toFixed(2)
                      }}KB</span
                    >
                    <span v-else
                      >{{ website_data.mediaLnks[0].size.toFixed(2) }}Byte</span
                    >
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="website_data.mxdContentErr.length !== 0"
          class="overflow-y-auto h-96 mt-4"
        >
          <h1 class="text-2xl text-center my-4 p-2">Mixed content errors</h1>
          <div
            v-for="err in website_data.mxdContentErr"
            :key="err"
            class="flex my-1 mx-auto flex-1 w-4/5 items-center justify-between bg-gray-200 text-red-600 border-2 border-red-600 p-2 shadow-md rounded-xl"
          >
            <div class="">{{ err.error_message }}</div>
            <hr />
          </div>
        </div>
        <div
          v-if="website_data.mxdContentWar.length !== 0"
          class="overflow-y-auto h-96 mt-4"
        >
          <h1 class="text-2xl text-center my-4 p-2">Mixed content warnings</h1>
          <div
            v-for="war in website_data.mxdContentWar"
            :key="war"
            class="flex my-1 mx-auto flex-1 w-4/5 items-center justify-between p-2 border-2 border-yellow-600 bg-yellow-200 shadow-md rounded-xl"
          >
            <div class="">{{ war.warning_message }}</div>
            <hr />
          </div>
        </div>
        <div
          v-if="website_data._404Err.length !== 0"
          class="overflow-y-auto h-96 mt-4"
        >
          <h1 class="text-2xl text-center my-4 p-2">404 Errors</h1>
          <div
            v-for="err in website_data._404Err"
            :key="err"
            class="my-1 mx-auto w-4/5 bg-gray-200 text-red-600 border-2 border-red-700 p-2 shadow-md rounded-xl"
          >
            <p>{{ err.url }}</p>
            <div class="">{{ err.error_message }}</div>
          </div>
        </div>
        <div
          v-if="website_data._404War.length !== 0"
          class="overflow-y-auto h-96 mt-4"
        >
          <h1 class="text-2xl text-center my-4 p-2">404 Warnings</h1>
          <div
            v-for="war in website_data._404War"
            :key="war"
            class="my-1 mx-auto w-4/5 p-2 border-2 border-yellow-600 bg-yellow-200 shadow-md rounded-xl"
          >
            <p>{{ war.url }}</p>
            <div class="">{{ war.warning_message }}</div>
          </div>
        </div>
      </div>
      <div
        ref="chartData"
        class="container mx-auto flex justify-between p-2 my-4"
      >
        <div
          class="w-2/5 p-1 my-1 mx-auto flex flex-col justify-center"
          v-if="!website_data.error && !error"
        >
          <h1 class="text-2xl">{{ $t("message.improvements") }}</h1>
          <div
            v-if="problems.length !== 0"
            class="flex flex-col p-2 my-2 overflow-y-auto h-64"
          >
            <div
              v-for="problem in problems"
              :key="problem"
              class="w-full p-2 my-1 bg-gray-300 rounded-xl"
            >
              {{ problem.message }}
            </div>
          </div>
          <div
            v-else
            class="flex flex-col p-2 my-2 overflow-y-auto h-64 justify-center items-center"
          >
            {{ $t("message.no_changes") }}
          </div>
        </div>
        <div class="w-2/5 mx-auto p-4 my-4" ref="chart">
          <DNChart v-if="loaded" :dataObject="dataObject" />
        </div>
      </div>
      <WaterFall v-if="loaded" :waterfallString="har" />
    </div>

    <div v-if="loading" class="text-center text-4xl p-4 m-auto">
      {{ $t("message.please_wait") }}.
      <div class="flex items-center justify-center mx-auto mt-10">
        <scaling-squares-spinner
          :animation-duration="1250"
          :size="65"
          :color="'#444444'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ScalingSquaresSpinner } from "epic-spinners";
import { BIconPlayCircleFill } from "bootstrap-vue";
import axios from "axios";
import { VueRecaptcha } from "vue-recaptcha";
import SelectLocale from "./components/SelectLocale.vue";
export default {
  components: {
    DNChart: () => import("./components/DNChart"),
    WaterFall: () => import("./components/WaterFall"),
    Tl: () => import("./components/Tl"),
    BIconPlayCircleFill,
    ScalingSquaresSpinner,
    VueRecaptcha,
    SelectLocale,
  },
  name: "App",
  data() {
    return {
      url: null,
      website_data: null,
      analyze: false,
      loading: false,
      loaded: false,
      error: null,
      requestedUrl: null,
      protocol: "https",
      chartdata: null,
      options: null,
      imageBytes: "",
      metrics: null,
      detect: null,
      robot: false,
      token: "",
      problems: [],
      urlError: "",
    };
  },
  mounted: () => {
    // getting the csrf
    axios
      .create({ withCredentials: true })
      .get("https://webc.adam-alsalehi.at/getcsrfToken")
      .then((response) => {
        axios.defaults.headers.common["CSRF-Token"] = response.data.csrfToken;
      }),
      (err) => {
        console.log(err);
      };
  },

  methods: {
    async check() {
      this.website_data = null;
      this.analyze = true;
      this.problems = [];
      this.urlError = "";
      if (!/ logo-and-form/.test(this.$refs.logoform.classList.value)) {
        this.$refs.logoform.classList.value += " logo-and-form";
      }
      if (!/ analyze-logo/.test(this.$refs.logodiv.classList.value)) {
        this.$refs.logodiv.classList.value += " analyze-logo";
      }
      if (!/ analyze-gradient/.test(this.$refs.gradient.classList.value)) {
        this.$refs.gradient.classList.value += " analyze-gradient";
      }
      if (!/ analyze-form/.test(this.$refs.form.classList.value)) {
        this.$refs.form.classList.value += " analyze-form";
      }
      this.$refs.protocol.classList.remove("before");
      this.$refs.input.classList.remove("before");
      this.$refs.submit.classList.remove("before");
      this.$refs.gradient.classList.remove("before");

      if (this.detect >= 1) {
        this.robot = true;
      }
      // check for url
      if (this.robot == true) {
        this.error = "Please verify that you are not a robot";
      } else if (this.robot == false) {
        if (this.url) {
          this.url = this.url.replaceAll(" ", "");
          if (this.url === "") {
            this.urlError = this.$i18n.t("message.url_error_empty");
          } else if (
            /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+-~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi.test(
              this.url
            ) !== true
          ) {
            this.urlError = this.$i18n.t("message.url_error_unvalid");
          } else {
            if (!this.protocol) {
              if (!/(http(s)?:\/\/)/gi.test(this.url)) {
                this.protocol = "https";
              } else {
                if (/(https:\/\/)/gi.test(this.url)) {
                  this.protocol = "https";
                  this.url = this.url.slice(8);
                } else if (/(http:\/\/)/gi.test(this.url)) {
                  this.protocol = "http";
                  this.url = this.url.slice(7);
                }
              }
            } else {
              if (/(https:\/\/)/gi.test(this.url)) {
                this.protocol = "https";
                this.url = this.url.slice(8);
              } else if (/(http:\/\/)/gi.test(this.url)) {
                this.protocol = "http";
                this.url = this.url.slice(7);
              }
            }
            this.requestedUrl = this.protocol + "://" + this.url;
            this.loading = true;
            this.error = "";
            if (this.$refs.chartData) {
              this.$refs.chart.innerHtml = "";
            }
            // call the api
            axios
              .create({withCredentials:true})
              .post("https://webc.adam-alsalehi.at/url-check", {
                url: this.requestedUrl,
              })
              .then((res) => {
                this.detect += 1;
                this.website_data = res.data;
                if (this.website_data.error) {
                  this.loading = false;
                } else {
                  this.website_data.error = "";
                  this.website_data.pageMetrics.TaskDuration = this.round(
                    this.website_data.pageMetrics.TaskDuration
                  );
                  this.website_data.pageMetrics.ScriptDuration = this.round(
                    this.website_data.pageMetrics.ScriptDuration
                  );
                  this.website_data.pageMetrics.RecalcStyleDuration =
                    this.round(
                      this.website_data.pageMetrics.RecalcStyleDuration
                    );
                  this.imageBytes = this.website_data.screen;
                  this.loading = false;
                  // chart Data
                  this.dataObject = this.website_data.chart;
                  this.har = this.website_data.har;
                  this.metrics = {
                    server: this.website_data.serverTime,
                    app: this.website_data.ttfb,
                    browser: this.website_data.fcp,
                  };
                  if (
                    this.website_data.ttfb >= 350 &&
                    this.website_data.ttfb < 700
                  ) {
                    this.problems.push({
                      message: this.$i18n.t("message.ok_ttfb_message"),
                    });
                  } else if (this.website_data.ttfb >= 700) {
                    this.problems.push({
                      message: this.$i18n.t("message.bad_ttfb_message"),
                    });
                  }
                  if (
                    this.website_data.fcp >= 1800 &&
                    this.website_data.fcp < 3000
                  ) {
                    this.problems.push({
                      message: this.$i18n.t("message.ok_fcp_message"),
                    });
                  } else if (this.website_data.fcp >= 3000) {
                    this.problems.push({
                      message: this.$i18n.t("message.bad_fcp_message"),
                    });
                  }
                  this.loaded = true;
                }
              })
              .catch((error) => {
                this.detect += 1;
                // this.error = err.response.data;
                if (/too many requests/gi.test(error.response.data)) {
                  this.error = this.$i18n.t("message.too_many_requests");
                } else {
                  this.error = this.$i18n.t(
                    "message.something_wrong_happenned"
                  );
                }
                this.loading = false;
              });
          }
        } else {
          this.website_data = null;
          this.urlError = this.$i18n.t("message.url_error_empty");
        }
      }
    },
    round(num) {
      num = num.toFixed(2);
      return num;
    },
    onVerify: function (response) {
      if (response) {
        this.robot = false;
        this.error = null;
        this.detect = null;
      }
    },
    onExpired() {
      this.$refs.recaptcha.reset();
      this.robot = true;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap");

#app {
  font-family: "Titillium Web", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;
  background-color: #fafafa;
  min-height: 100vh;
}

.languages {
  position: relative;
  top: 2rem;
}
.border-gradient-purple::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 3px;
  right: 1px;
  bottom: 3px;
  padding: 2px;
  background: -webkit-linear-gradient(
    120deg,
    rgba(158, 0, 93, 1) 5%,
    rgba(0, 113, 188, 1) 95%
  );
  background: linear-gradient(
    120deg,
    rgba(158, 0, 93, 1) 5%,
    rgba(0, 113, 188, 1) 95%
  );
  -webkit-mask: -webkit-linear-gradient(#fafafa 0 0) content-box,
    -webkit-linear-gradient(#fafafa 0 0);
  -webkit-mask: linear-gradient(#fafafa 0 0) content-box,
    linear-gradient(#fafafa 0 0);
  mask-composite: add, add;
  -webkit-mask-composite: source-out;
  mask-composite: exclude;
  border-radius: 0.75rem;
  pointer-events: none;
}
.border-gradient-purple {
  position: relative;
  margin: 5px;
  background: #fff;
}
.logo-and-form {
  display: flex;
  justify-content: start;
  align-items: center;
}
.analyze-logo {
  display: flex;
  justify-content: start;
  align-items: center;
}
.logo img {
  width: 20rem;
  margin: 3rem auto;
}
.analyze-logo img {
  width: 7rem;
  margin: 0;
}

.before .protocol {
  padding: 0.85rem 1.4rem;
  font-size: 1.4rem;
}
.before .input-url {
  padding: 1rem 1.4rem;
  font-size: 1.4rem;
}
.before .submit-btn {
  padding: 1rem 1.4rem;
  font-size: 1.4rem;
}
.before .border-gradient-purple {
  padding: 0.75rem 1.5rem;
}
.analyze-form {
  width: 75%;
  margin-left: 4rem;
}
.analyze-gradient {
  width: 100%;
}
.languages select {
  font-size: 1.2rem;
  padding: 6px;
}

.bg-fafafa {
  background: #fafafa;
}
.input-url {
  padding: 8px;
  margin-left: 0.8rem;
}
.submit-btn {
  font-weight: bold;
  width: 100%;
  background: rgb(158, 0, 93);
  background: linear-gradient(
    120deg,
    rgba(158, 0, 93, 1) 5%,
    rgba(0, 113, 188, 1) 95%
  );
}
.hover\:to-gradient:hover {
  background: rgba(0, 113, 188, 1);
  background: linear-gradient(
    60deg,
    rgba(0, 113, 188, 1) 5%,
    rgba(158, 0, 93, 1) 95%
  );
}
.tooltip {
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  text-align: center;
  font-size: 13px;
  background: hsla(0, 0%, 98%, 0.9294117647058824);
  padding: 2px;
  border: 1px solid;
  margin-top: 2rem;
}

.has-tooltip:hover .tooltip {
  opacity: 1;
  z-index: 50;
  text-align: center;
  font-size: 13px;
  background: #fafafaed;
  padding: 2px;
  border: 1px solid;
  margin-top: 2rem;
  transition: opacity 0.2s ease-in;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 8px;
  background: #e5e7eb;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background: #444;
}

.bg-green-500 {
  background: linear-gradient(to bottom, #09bb23 0px, #079b1d 100%);
}

.overflow-y-auto,
.wrapper {
  scrollbar-color: #444 #e5e7eb;
  scrollbar-width: thin;
}

.chartBox {
  width: 400px;
}

.resource-legend {
  margin: 15px 0px 15px 0px;
}

.b-icon.bi {
  width: 12px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px !important;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px !important;
  }
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
</style>
