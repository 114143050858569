import Vue from 'vue'
import App from './App.vue'
import './index.css'

import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

Vue.config.productionTip = false

const messages = {
  English :{
    message :{
      last_followed_url: 'last followed URL',
      redirects: 'Redirects',
      total_loading_time: 'Total loading time',
      loading_time: 'Loading time',
      total_time_taken_for_javasctript: 'Total time taken for Javasctript',
      total_time_taken_for_CSS: 'Total time taken for CSS',
      number_of_scripts_run_on_the_page: 'Number of scripts run of the page',
      number_of_stylesheets_linked_on_the_page: 'Number of used stylesheets on the page',
      number_of_Fonts_linked_on_the_page: 'Number of loaded fonts on the page',
      num_of_media: 'Total number of Medias',
      size_of_media: 'Total size of Medias',
      biggest_media: 'Biggest Media',
      please_wait: 'Please wait, the website is being analyzed',
      performance_timings_measured: 'Performance timings are being measured',
      enter_url: 'Enter your website URL',
      analyze: 'ANALYZE',
      improvements: 'Recommended changes',
      no_changes: 'no recomended changes',
      bad_fcp_message: "bad fcp ",
      ok_fcp_message: "ok fcp",
      bad_ttfb_message: "bad ttfb",
      ok_ttfb_message: " ok ttfb",
      url_error_empty: "Please enter a URL",
      url_error_unvalid: "Please enter a valid URL",
      performance_timings: "Performance timings",
      something_wrong_happenned: "Something went wrong. Please refresh the page and try again",
      too_many_requests: "TOO MANY REQUESTS",
      sec: 'sec',
      size: 'Size'
    }
  },
  Deutsch :{
    message :{
      last_followed_url: 'letzte gefolgte URL',
      redirects: 'Weiterleitungen',
      total_loading_time: 'gesamte Ladezeit',
      loading_time: 'Ladezeit',
      total_time_taken_for_javasctript: 'Zeit gebraucht für Javascript',
      total_time_taken_for_CSS: 'Zeit gebraucht für CSS',
      number_of_scripts_run_on_the_page: 'Anzahl der ausgefürten Scripts die auf der Seite',
      number_of_stylesheets_linked_on_the_page: 'Anzahl der verwendeten Stylesheets auf der Seite',
      number_of_Fonts_linked_on_the_page: 'Anzahl der geladenen Fonts auf der Seite ',
      num_of_media: 'Anzahl der Medien',
      size_of_media: 'gesamte Größe aller Medien',
      biggest_media: 'Größtes Media',
      please_wait: 'Bitte warten, die Webseite wird analysiert',
      performance_timings_measured: 'Leistungszeiten werden berechnet',
      enter_url: 'Website URL hier eingeben',
      analyze: 'ANALYSIEREN',
      improvements: 'Empfohlene Änderungen',
      no_changes: 'Keine Änderungen vorgeschlagen',
      bad_fcp_message: "schlechte fcp ",
      ok_fcp_message: "ok fcp",
      bad_ttfb_message: "schlechte ttfb",
      ok_ttfb_message: " ok ttfb",
      url_error_empty: "Bitte geben Sie eine URL ein",
      url_error_unvalid: "Bitte geben Sie eine gültige URL ein",
      performance_timings: "Leistungszeiten",
      something_wrong_happenned: "Etwas ist schiefgelaufen. Bitte die Seite neuladen und erneut versuchen",
      too_many_requests: "ZU VIELE ABFRAGEN",
      sec: 'Sek',
      size: 'Grösse'
    }
  }
}

const i18n = new VueI18n({
  locale: 'English',
  messages
});

new Vue({
  render: h => h(App),
  i18n
}).$mount('#app')
